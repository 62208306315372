import './MaxAddAndRemoveProduct.css';
import { Context } from '../../Store';
import { Button } from 'react-materialize';
import React, { useContext, useEffect, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { ProductInterface } from '../../product/ProductInterface';

export function MaxAddAndRemoveProduct(props: {
  message: string;
  cart?: boolean;
  product: ProductInterface;
}) {
  const [state, dispatch] = useContext(Context);
  const [inputValue, setInputValue] = useState('');
  const cart =
    state.merchantParams?.noClient
      ? state.cart[state.merchantParams.noClient] ||
      state.cart[0]
      : state.cart[0];
  const peso =
    props.product.details.find((detail) => detail.type === 'peso')?.value || 1;
  const indPeso =
    props.product.details.find((detail) => detail.type === 'indPeso')?.value ===
    'S';

  useEffect(() => {
    setInputValue(String(cart[props.product.id] || 0));
  }, [props.product.id, cart]);

  const formatQuantity = (num) => {
    const rounded = parseFloat(num.toFixed(1));

    if (Number.isInteger(rounded)) {
      return String(Math.trunc(rounded));
    }

    return String(rounded);
  }

  const addProductToCart = () => {
    const increment = indPeso && peso > 1 ? peso : 1;

    const currentQuantity = Math.min(
      parseFloat(inputValue) + increment,
      props.product.quantity
    );

    const displayValue = formatQuantity(currentQuantity);
    setInputValue(String(displayValue));

    dispatch({
      type: 'ADD-CARD',
      payload: {
        [props.product.id]: currentQuantity,
      },
    });
  };

  const removeProductFromCart = () => {
    const decrement = indPeso && peso > 1 ? peso : 1;
    const currentQuantity = Math.max(parseFloat(inputValue) - decrement, 0);


    setInputValue(String(formatQuantity(currentQuantity)));
    dispatch({
      type: 'ADD-CARD',
      payload: {
        [props.product.id]: currentQuantity,
      },
    });
  };

  const handleInputChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    setInputValue(String(value));
  };

  const handleInputBlur = () => {
    let value = parseInt(inputValue, 10);

    if (isNaN(value)) {
      value = 0;
    }

    if (indPeso && peso > 1) {
      value = Math.min(props.product.quantity, Math.floor(value / peso) * peso);
    }

    value = Math.max(0, Math.min(value, props.product.quantity));
    setInputValue(String(value));

    if (value > 0) {
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [props.product.id]: value,
        },
      });
    }
  };

  return !cart ? (
    <div
      id={`Cashier-item-adding-${props.product.id}`}
      className={'Max-header-cart-modal-product-addg'}
    >
      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        disabled={!cart[props.product.id]}
        onClick={removeProductFromCart}
        icon={<MinusOutlined className={'max-cart-add-but'} />}
      />
      <p className={'Max-cart-quantity'}>
        {props.message === '' ? cart[props.product.id] : props.message}
      </p>
      <Button
        floating
        waves={'light'}
        className={'max-cart-add-but'}
        onClick={() => addProductToCart(props.product.id)}
        icon={<PlusOutlined className={'max-cart-add-but'} />}
        disabled={
          cart[props.product.id] === props.product.quantity ||
          props.product.id === state.ruleParams.idProductShipping
        }
      />
    </div>
  ) : (
    <div className={'Max-cart-control-cont2'}>
      <div className={'Max-cart-control-cont'}>
        <MinusOutlined
          onClick={removeProductFromCart}
          className={'Max-cart-control-icon'}
        />
        <input
          type='number'
          className='Max-cart-control-text'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          min="0"
        />
        <PlusOutlined
          onClick={addProductToCart}
          className={'Max-cart-control-icon'}
        />
      </div>
      {!props.cart ? (
        <p className={'Max-cart-control-text2'}>{props.message}</p>
      ) : null}
    </div>
  );
}
